// import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import ReactHtmlParser from "react-html-parser"
import Iframe from "react-iframe"
import Comments from "../components/Comments"
import SidebarPostInfo from "../components/SidebarPostInfo"
import Card from "../components/Card/Card"
// Material UI Kit
import CardBody from "../components/Card/CardBody"
import GridContainer from "../components/Grid/GridContainer"
import GridItem from "../components/Grid/GridItem"
// Styles
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery"
import imagesStyles from "../assets/jss/material-kit-pro-react/imagesStyles.jsx"

const style = {
  ...imagesStyles,
}

function YoutubeTemplate({ ...props }) {
  const isMobile = useMediaQuery("(max-width:767px)")

  const {
    pageContext: {
      pageData: {
        avatar,
        created,
        bodyRaw,
        title,
        drupalId,
        type,
        fieldImage,
        fieldLike,
        relationships,
        drupalInternalNid,
      },
    },
  } = props

  let feed_body_elements = new ReactHtmlParser(bodyRaw, {
    transform: function transform(node) {
      if (node.type === "tag" && node.name === "iframe") {
        let url = node.attribs.src
        if (url.search("http") === -1) {
          if (url.search("//") === -1) {
            url = "//" + url
          }
          url = "https:" + url
        }
        let id = url.split("/").pop()
        return (
          <div key={id} className={"embed-responsive embed-responsive-16by9"}>
            <Iframe
              className={"embed-responsive-item"}
              url={url}
              id={id}
              allowFullScreen
            />
          </div>
        )
      }
      return undefined
    },
  })

  const getContent = type => {
    for (let i = 0; i < feed_body_elements.length; i++) {
      if (feed_body_elements[i].type === "div" && type === "video") {
        return feed_body_elements[i]
      }
      if (feed_body_elements[i].type === undefined && type === "text") {
        return feed_body_elements[i]
      }
    }
  }

  const sidebar = () => (
    <SidebarPostInfo
      postId={drupalInternalNid}
      uuid={drupalId}
      type={type}
      created={created}
      authorName={relationships.uid.name}
      authorImage={avatar}
      fieldLike={fieldLike}
    />
  )

  return (
    <Layout {...props}>
      <div style={style.root}>
        <GridContainer
          id={"sidebar"}
          spacing={3}
          direction="row"
          justify="center"
          alignContent="center"
        >
          {!isMobile && (
            <GridItem xs={12} md={2}>
              {sidebar()}
            </GridItem>
          )}
          <GridItem id={"card-wrapper"} xs={12} md={10}>
            <Card
              id={"card"}
              style={style.carousel}
              xs={12}
              sm={12}
              md={8}
              lg={8}
            >
              <div id={"carousel-wrapper"}>
                {getContent("video")}
                {isMobile && sidebar()}
              </div>
              <CardBody>
                <div style={style.fullWidth}>{getContent("text")}</div>
              </CardBody>
            </Card>
            <div style={{ padding: "10px" }}>
              <Comments id={drupalId} type={type} />
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </Layout>
  )
}

export default YoutubeTemplate
